import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Dr.R.Karthik" },
  { meta: "last name", metaInfo: "Mr.P.Ramalingam" },
  { meta: "DOB", metaInfo: "10-10-1982" },
  { meta: "Nationality", metaInfo: "Indian – Hindu - BC" },
  { meta: "Qualification", metaInfo: "PHd,MD(AM),MBA,MSW,LLB" },
  { meta: "Address", metaInfo: "Cuddalore 607002" },
  { meta: "phone", metaInfo: "+91 7667667600 " },
  { meta: "Email", metaInfo: "myrightsmyhand@gmail.com" },
  { meta: "Member", metaInfo: "Tamil Nadu Electricity  Consumer Residental Form , TANGEDCO, TNEB, Cuddalore" },
  { meta: "langages", metaInfo: "English, Tamil, Hindi" },
  { meta: "Blood Group", metaInfo: "O+ve" },
  { meta: "Administrative Editor", metaInfo: "Indraya Theerpu (monthly magazine )" },
  { meta: "Consumer Member", metaInfo: "Civil Supplies and Consumer Protection Department, Cuddalore." },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
