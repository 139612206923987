import React, { useEffect, useState } from "react";
import { db } from '../../firebase';
import { query, collection, getDocs, where } from "firebase/firestore";


const Portfolio = () => {

  const [galleryimages, setGalleryimage] = useState("");

  useEffect(() => {
    fetchgalleryimage();

  }, []);


  const fetchgalleryimage = async () => {
    const q = query(collection(db, 'gallery'));
    const querySnapshot = await getDocs(q)
    const docs = querySnapshot.docs
    const nftdata = docs.map((doc) => doc.data());
    setGalleryimage(nftdata);
  }

  console.log(galleryimages);
  return (
    <section className="main-content ">
      <div className="container">
        <div className="row">
          {galleryimages.length > 0 && (
            <>
              {galleryimages.map(data => (
                <div key={data.id} class="col-md-4" >
                  <img src={data.image_url} alt={data.image_description} width="100%" />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
