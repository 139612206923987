import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>5,Sakkarai street, Pudupalayam, Cuddalore – 607001, Tamilnadu.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:abings002@gmail.com">aksdrkarthik@yahoo.co.in</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">call me</span>{" "}
        <a href="tel:+919442482695">+91 766 766 7600 </a> 
      </p>

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-whatsapp position-absolute"></i>
        <span className="d-block">WhatsApp me</span>{" "}
        <a href="https://wa.me/+917667667600" target="_blank">Chat With me</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
