import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
} from "firebase/auth";
import {
  getFirestore,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCwMcHS8oE2gWj0ySbZTj9uwnN5Sn3gabI",
    authDomain: "international-university-b4094.firebaseapp.com",
    projectId: "international-university-b4094",
    storageBucket: "international-university-b4094.appspot.com",
    messagingSenderId: "584362960199",
    appId: "1:584362960199:web:dd6565f71e226f60219862",
    measurementId: "G-5YT39CGGBY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();


export const storage = getStorage(app);
export {
  firebaseConfig,
  auth,
  db,
};