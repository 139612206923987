import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import './style.css'
import { db } from '../../firebase';
import { query, collection, getDocs, where } from "firebase/firestore";



const Blog = () => {

  const [orgdata, setOrgdata] = useState("");

  useEffect(() => {
    fetchorgdata();

  }, []);

  const fetchorgdata = async () => {
    const q = query(collection(db, 'organizations'));
    const querySnapshot = await getDocs(q)
    const docs = querySnapshot.docs
    const nftdata = docs.map((doc) => doc.data());
    setOrgdata(nftdata);
  }
  console.log(orgdata)
  return (
    <>
      <section className="main-content ">
        <div className="container">
          <div class="row">
            <div class="col-md-12">
              <table>
                <tr>
                  <th>#</th>
                  <th>Organization name</th>
                  <th>Role</th>
                  <th>Link</th>
                </tr>
                {orgdata.length > 0 && (
                  <>
                    {orgdata.map(data => (
                        <tr key={data.id}>
                          <td>{data.id}</td>
                          <td>{data.org_name}</td>
                          <td>{data.role}</td>
                          <td><a href={data.link} style={{textDecoration:'none', color:'#FFB400'}}>Click Here</a></td>
                        </tr>
                        ))}
                      </>
                    )}

                  </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
